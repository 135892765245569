<template>
  <v-autocomplete
    v-model="model"
    :items="items"
    :item-text="slotItem.text"
    :item-value="slotItem.value"
    :search-input.sync="search"
    :label="label"
    :multiple="isMultiple"
    :chips="isMultiple"
    :deletable-chips="isDeletable"
    :cache-items="hasCacheItems"
    :return-object="returnObject"
    :disabled="isDisabledInput"
    :clearable="hasDisableClearable === null || hasDisableClearable !== true"
    outlined
    hide-details
    :rules="isRequired ? requiredRules : []"
    :validate-on-blur="isRequired"
    @input="onInput">
  </v-autocomplete>
</template>

<script>
  import formRules from '@/mixins/formRules';

  export default {
    name: "SelectGeneric",

    mixins: [formRules],

    props: {
      items: {type: Array, default: () => []},
      label: {type: String, default: ""},
      isMultiple: {type: Boolean, default: false},
      slotItem: {type: Object, default: () => {return {text: 'text', value: 'value'}}},
      value: {type: [Array, Object, String, Number, Boolean], default: () => null},
      hasCacheItems: {type: Boolean, default: false},
      isDeletable: {type: Boolean, default: false},
      hasDisableClearable: {type: Boolean, default: true},
      isDisabledInput: {type: Boolean, default: false},
      isDefaultFirst: {type: Boolean, default: false},
      isRequired: {type: Boolean, default: false},
      returnObject: {type: Boolean, default: true}
    },

    data() {
      return {
        model: "",
        search: null
      }
    },

    mounted() {
      if (this.isDefaultFirst && this.items.length > 0)
        this.model = this.items[0];
      else
        this.model = this.value;

      if (this.model && typeof this.model === "object")
        this.onInput(this.model);

    },

    methods: {
      onInput(value) {
        this.$emit('input', value);
      }
    }

  }
</script>

<style scoped>

</style>
